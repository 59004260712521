import { breakpoints, fonts, mixins } from "@/styles/variables/variables";
import Link from "next/link";
import Masonry from "react-responsive-masonry";
import styled from "styled-components";
import { ErrorLink } from "../footer/team/components";
import { New } from "../index-page/cards/card/card-components";
import { ImgFoundation } from "./card/FoundationCardHeader";

// --- header

export const Picture_HbCard = styled.div`
  border: 1px solid var(--wh04);
  position: relative;
  pointer-events: none;
  height: 100%;
  width: 100%;

  ${({ isSlug }) =>
    isSlug
      ? `
    border-radius: 57px;
    @media ${breakpoints.desktopXl} {
      border-radius: 4.457vw;
    }
    @media ${breakpoints.tabletL} {
      border-radius: 5.572vw;
    }
    @media ${breakpoints.tabletM} {
      border-radius: 7.672vw;
    }
    @media ${breakpoints.mobile} {
      border-radius: 5.016vw;
    }
    `
      : `
   border-radius: 24px;
  @media ${breakpoints.desktopXl} {
    border-radius: 1.876vw;
  }
  @media ${breakpoints.tabletL} {
    border-radius: 2.346vw;
  }
  @media ${breakpoints.tabletM} {
    border-radius: 3.23vw;
  }
  @media ${breakpoints.mobile} {
    border-radius: 7.524vw;
  }
  `}
  &:before {
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    content: "";
    position: absolute;
    ${({ circle }) =>
      `
    background: radial-gradient(
      50% 50% at 50% 50%,
      ${circle} 0%,
      rgba(0, 146, 160, 0) 100%
      );
      `}
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.65;
    transition: opacity 0.3s;
    background-size: 130px 130px;
    @media ${breakpoints.desktopXl} {
      background-size: 9.028vw 9.028vw;
    }
    @media ${breakpoints.tabletL} {
      background-size: 15.588vw 15.588vw;
    }
    @media ${breakpoints.tabletM} {
      background-size: 15.588vw 15.588vw;
    }
    @media ${breakpoints.mobile} {
      background-size: 40.625vw 40.625vw;
    }
  }
`;
export const Tags_HbCard = styled.ul`
  padding: 0 4px;
  gap: 7px;
  margin-bottom: 12px;
  @media ${breakpoints.desktopXl} {
    padding: 0 0.313vw;
    gap: 0.547vw;
    margin-bottom: 0.938vw;
  }
  @media ${breakpoints.tabletL} {
    padding: 0 0.391vw;
    gap: 0.684vw;
    margin-bottom: 1.173vw;
  }
  @media ${breakpoints.tabletM} {
    padding: 0 0.538vw;
    gap: 0.942vw;
    margin-bottom: 1.615vw;
  }
  @media ${breakpoints.mobile} {
    padding: 0 1.254vw;
    gap: 2.194vw;
    margin-bottom: 3.762vw;
  }
`;
export const Tag_HbCard = styled.li`
  ${fonts.manrope4}
  ${mixins.fs10}
  line-height: 133%;
  letter-spacing: 0.03em;
  color: var(--graphit-bb);
  background-color: var(--wh04);
  border-radius: 5px;
  padding: 1px 7px;
  @media ${breakpoints.desktopXl} {
    padding: 0.078vw 0.547vw;
  }
  @media ${breakpoints.tabletL} {
    padding: 0.098vw 0.684vw;
  }
  @media ${breakpoints.tabletM} {
    padding: 0.135vw 0.942vw;
  }
  @media ${breakpoints.mobile} {
    padding: 0.313vw 2.194vw;
  }
`;
// --- content
export const Title_HbCard = styled.h2`
  ${fonts.manrope5}
  ${mixins.fs18}
  line-height: 122%;
  color: var(--wh9);
  margin-bottom: 8px;
  @media ${breakpoints.desktopXl} {
    margin-bottom: 0.625vw;
  }
  @media ${breakpoints.tabletL} {
    margin-bottom: 0.782vw;
  }
  @media ${breakpoints.tabletM} {
    margin-bottom: 1.077vw;
  }
  @media ${breakpoints.mobile} {
    margin-bottom: 2.508vw;
  }
`;
export const Desc_HbCard = styled.p`
  ${fonts.manrope4}
  ${mixins.fs14}
  line-height: 143%;
  letter-spacing: 0.01em;
  color: var(--wh6);
  margin-bottom: 16px;
  @media ${breakpoints.desktopXl} {
    margin-bottom: 1.251vw;
  }
  @media ${breakpoints.tabletL} {
    margin-bottom: 1.564vw;
  }
  @media ${breakpoints.tabletM} {
    margin-bottom: 2.153vw;
  }
  @media ${breakpoints.mobile} {
    margin-bottom: 5.016vw;
  }
`;
export const Link_HbCard = styled.a`
  max-width: 90%;
  margin-bottom: 12px;
  display: flex;
  ${fonts.manrope4}
  ${mixins.fs14}
  line-height: 114%;
  letter-spacing: 0.01em;
  color: var(--wh4);
  transition: color 0.3s;
  & > svg {
    flex-shrink: 0;
    vertical-align: middle;
    width: 12px;
    height: 13px;
    margin-right: 6px;
    margin-top: 2px;
    @media ${breakpoints.desktopXl} {
      margin-top: 0.156vw;
      margin-right: 0.469vw;
      width: 0.938vw;
      height: 1.016vw;
    }
    @media ${breakpoints.tabletL} {
      margin-top: 0.196vw;
      margin-right: 0.587vw;
      width: 1.173vw;
      height: 1.271vw;
    }
    @media ${breakpoints.tabletM} {
      margin-top: 0.269vw;
      margin-right: 0.808vw;
      width: 1.615vw;
      height: 1.75vw;
    }
    @media ${breakpoints.mobile} {
      margin-top: 0.627vw;
      margin-right: 1.881vw;
      width: 3.762vw;
      height: 4.075vw;
    }
    & circle {
      transition: stroke-opacity 0.3s;
    }
    & path {
      transition: fill-opacity 0.3s;
    }
  }
  @media ${breakpoints.desktopXl} {
    margin-bottom: 0.938vw;
  }
  @media ${breakpoints.tabletL} {
    margin-bottom: 1.173vw;
  }
  @media ${breakpoints.tabletM} {
    margin-bottom: 1.615vw;
  }
  @media ${breakpoints.mobile} {
    margin-bottom: 3.762vw;
  }
  &:hover {
    color: var(--wh6);
    & > svg {
      & circle {
        stroke-opacity: 0.6;
      }
      & path {
        fill-opacity: 0.6;
      }
    }
  }
`;
// --- footer
export const ShareWr_HbCard = styled.ul`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 24px;
  margin-bottom: 11px;
  @media ${breakpoints.desktopXl} {
    height: 1.876vw;
    margin-bottom: 0.86vw;
  }
  @media ${breakpoints.tabletL} {
    height: 2.346vw;
    margin-bottom: 1.075vw;
  }
  @media ${breakpoints.tabletM} {
    height: 3.23vw;
    margin-bottom: 1.48vw;
  }
  @media ${breakpoints.mobile} {
    height: 7.524vw;
    margin-bottom: 3.448vw;
  }
`;
export const ShareLink_HbCard = styled.a`
  height: 100%;
  ${fonts.manrope4}
  ${mixins.fs10}
  line-height: 133%;
  letter-spacing: 0.03em;
  color: var(--graphit-bb);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(217, 217, 217, 0.04);
  padding: 0 10px;
  @media ${breakpoints.desktopXl} {
    padding: 0 0.782vw;
  }
  @media ${breakpoints.tabletL} {
    padding: 0 0.978vw;
  }
  @media ${breakpoints.tabletM} {
    padding: 0 1.346vw;
  }
  @media ${breakpoints.mobile} {
    padding: 0 3.135vw;
  }
`;

export const MasonryWithGap = styled(Masonry)`
  gap: 20px !important;
  @media ${breakpoints.desktopXl} {
    gap: 1.564vw !important;
  }
  @media ${breakpoints.tabletL} {
    gap: 1.955vw !important;
  }
  @media ${breakpoints.tabletM} {
    gap: 2.692vw !important;
  }
  @media ${breakpoints.mobile} {
    gap: 6.27vw !important;
  }
  & > div {
    gap: 20px !important;
    @media ${breakpoints.desktopXl} {
      gap: 1.564vw !important;
    }
    @media ${breakpoints.tabletL} {
      gap: 1.955vw !important;
    }
    @media ${breakpoints.tabletM} {
      gap: 2.692vw !important;
    }
    @media ${breakpoints.mobile} {
      gap: 6.27vw !important;
    }
  }
`;

const showOnHover = `position: absolute;
  opacity: 0;
  z-index: 1;
  top: 22px;
  width: 28px;
  height: 28px;
  transition: opacity 0.3s;
  @media ${breakpoints.desktopXl} {
    width: 2.189vw;
    height: 2.189vw;
    top: 1.528vw;
  }
  @media ${breakpoints.tabletL} {
    width: 2.737vw;
    height: 2.737vw;
    top: 2.638vw;
  }
  @media ${breakpoints.tabletM} {
width: 3.769vw;
  height: 3.769vw;
    top: 2.638vw;
  }
  @media ${breakpoints.mobile} {
    width: 8.777vw;
    height: 8.777vw;
    top: 6.875vw;
    // opacity: 1;
  }
  & > svg{
    width: 100%;
    height: 100%;
  }
`;

export const Slug = styled(Link)`
  ${showOnHover};
  right: 19px;
  @media ${breakpoints.desktopXl} {
    right: 1.486vw;
  }
  @media ${breakpoints.tabletL} {
    right: 1.857vw;
  }
  @media ${breakpoints.tabletM} {
    right: 2.557vw;
  }
  @media ${breakpoints.mobile} {
    right: 5.956vw;
  }

  /* & #slug-arrow {
    transition: transform 0.3s;
  } */
  & > svg {
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    border-radius: 30%;
  }
  & #slug-bg {
    transition:
      fill-opacity 0.3s,
      transform 0.3s;
    transform-origin: center center;
  }
  &:hover {
    & #slug-bg {
      fill-opacity: 0.08;
    }
  }
  &:active {
    & #slug-bg {
      transform: scale(0.9);
    }
    /* & #slug-arrow {
      transform: translate(2px, -2px);
    } */
  }
`;

export const Fav = styled.button`
  ${showOnHover};

  left: 19px;
  @media ${breakpoints.desktopXl} {
    left: 1.486vw;
  }
  @media ${breakpoints.tabletL} {
    left: 1.857vw;
  }
  @media ${breakpoints.tabletM} {
    left: 2.557vw;
  }
  @media ${breakpoints.mobile} {
    left: 5.956vw;
  }
  & > svg {
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    border-radius: 30%;
    & path {
      transition:
        fill-opacity 0.3s,
        transform 0.3s;
      transform-origin: center center;
    }
  }

  &:hover {
    & > svg > path:first-child {
      fill-opacity: 0.08;
    }
  }
  &:active {
    & > svg > path {
      &:first-child {
        transform: scale(0.9);
      }
      &:nth-child(2) {
        fill-opacity: 0.4;
      }
    }
  }
  ${({ is_bookmarked }) =>
    is_bookmarked &&
    `opacity: 1 !important;

    & > svg > path:nth-child(2) {
      fill-opacity: 0.4;
    }
  
  &:active {
    & > svg > path:nth-child(2) {
      fill-opacity: 0;
    }
  }
  
  
  `}
`;

export const HandbookCardSt = styled.div`
  flex-shrink: 0;
  background-color: var(--wh02);
  border-width: 1.74px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 8px 8px 12px;
  border-radius: 32px;
  position: relative;
  ${({ is_active }) =>
    `border-style: ${is_active ? "solid" : "dashed"}; 
    border-color: var(--wh${is_active ? "04" : "4"});`}
  ${({ disableInBookmarkMode }) =>
    `opacity: ${disableInBookmarkMode ? 0.5 : 1};`};
  @media ${breakpoints.desktopXl} {
    border-radius: 2.502vw;
    padding: 0.625vw 0.625vw 0.938vw;
  }
  @media ${breakpoints.tabletL} {
    border-radius: 3.128vw;
    padding: 0.782vw 0.782vw 1.173vw;
  }
  @media ${breakpoints.tabletM} {
    border-radius: 4.307vw;
    padding: 1.077vw 1.077vw 1.615vw;
  }
  @media ${breakpoints.mobile} {
    border-radius: 10.031vw;
    padding: 2.508vw 2.508vw 3.762vw;
  }
  & > ${ErrorLink} {
    align-self: center;
    margin-top: 11px;
    @media ${breakpoints.desktopXl} {
      margin-top: 0.86vw;
    }
    @media ${breakpoints.tabletL} {
      margin-top: 1.075vw;
    }
    @media ${breakpoints.tabletM} {
      margin-top: 1.48vw;
    }
    @media ${breakpoints.mobile} {
      margin-top: 3.448vw;
    }
    & > span {
      ${mixins.fs8}
      color: var(--wh2);
      &:hover {
        color: var(--wh4);
      }
      &:active {
        color: var(--wh2);
      }
    }
  }

  ${Fav} {
    & path {
      stroke-opacity: 0.9;
    }
  }

  &:hover {
    & ${Slug}, ${Fav} {
      opacity: 1;
    }
    & ${Picture_HbCard}::before {
      opacity: 1;
    }
    ${New} {
      opacity: 0;
    }
    & ${ImgFoundation} {
      transform: scale(1.05);
    }

    & img[data-svg="animate-svg"] {
      transform: scale(1.05) translate(-50%, -50%);
    }
  }
  ${({ hovered }) =>
    hovered &&
    `
  & ${Slug}, ${Fav} {
      opacity: 1;
    }
    & ${Picture_HbCard}::before {
      opacity: 1;
    }
    ${New} {
      opacity: 0;
    }
    & ${ImgFoundation} {
      transform: scale(1.05);
    }

    & img[data-svg="animate-svg"] {
      transform: scale(1.05) translate(-50%, -50%);
    }
  `}
`;

// -------

export const FirstLineContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 21px;
  @media ${breakpoints.desktopXl} {
    gap: 1.642vw;
  }
  @media ${breakpoints.tabletL} {
    gap: 2.053vw;
  }
  @media ${breakpoints.tabletM} {
    gap: 2.826vw;
  }
  @media ${breakpoints.mobile} {
    gap: 6.583vw;
  }
  & > ${HandbookCardSt} {
    width: 257px;
    @media ${breakpoints.desktopXl} {
      width: 20.094vw;
    }
    @media ${breakpoints.tabletL} {
      width: 25.122vw;
    }
    @media ${breakpoints.tabletM} {
      width: 34.59vw;
    }
    @media ${breakpoints.mobile} {
      width: 80.564vw;
    }
  }
`;

export const WrPadding = styled.div`
  padding: 0 6px 0 10px;
  @media ${breakpoints.desktopXl} {
    padding: 0 0.469vw 0 0.782vw;
  }
  @media ${breakpoints.tabletL} {
    padding: 0 0.587vw 0 0.978vw;
  }
  @media ${breakpoints.tabletM} {
    padding: 0 0.808vw 0 1.346vw;
  }
  @media ${breakpoints.mobile} {
    padding: 0 1.881vw 0 3.135vw;
  }
`;

export const HbLayoutWrapper = styled.div`
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr;
`;

export const Error = styled(ErrorLink)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
  padding: 10px;
  bottom: 34px;
  @media ${breakpoints.desktopXl} {
    bottom: 2.66vw;
    padding: 0.78vw;
  }
  @media ${breakpoints.tabletL} {
    bottom: 3.32vw;
    padding: 0.98vw;
  }
  @media ${breakpoints.tabletM} {
    bottom: 4.58vw;
    padding: 1.35vw;
  }
  @media ${breakpoints.mobile} {
    bottom: 3.76vw;
    left: 3.76vw;
    padding: 3.13vw;
    transform: none;
  }
`;
