const { breakpoints } = require("@/styles/variables/variables");

export const convertPxToVw = (property, value) => {
  const values = value
    .match(/(\d+)(px)?/g)
    .map((item) => item.replace("px", ""));

  if (!values) return `${property}: ${value}`; // Если не удалось распарсить, возвращаем оригинал

  return `
    ${property}: ${value};
    @media ${breakpoints.desktopXl} {
      ${property}: ${values.map((val) => `${(val * 0.08).toFixed(2)}vw`).join(" ")};
    }
    @media ${breakpoints.tabletL} {
      ${property}: ${values.map((val) => `${(val * 0.1).toFixed(2)}vw`).join(" ")};
    }
    @media ${breakpoints.tabletM} {
      ${property}: ${values.map((val) => `${(val * 0.13).toFixed(2)}vw`).join(" ")};
    }
    @media ${breakpoints.mobile} {
      ${property}: ${values.map((val) => `${(val * 0.31).toFixed(2)}vw`).join(" ")};
    }
    `;
};
