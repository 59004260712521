import { LegalDateTag } from "@/components/legal/components";
import { convertPxToVw } from "@/services/convertPxToVw";
import { breakpoints, fonts, mixins } from "@/styles/variables/variables";
import styled from "styled-components";
import { Fav, Link_HbCard } from "../components";
import { ImageCaption } from "./ImageCaption";
import { Time, paddings } from "./text.components";

export const HbPageWrapper = styled.div`
  width: 572px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: left;
  padding-top: 58px;
  margin-bottom: 77px;
  @media ${breakpoints.desktopXl} {
    margin-bottom: 6.02vw;
    padding-top: 58px;
    width: 44.722vw;
  }
  @media ${breakpoints.tabletL} {
    margin-bottom: 7.527vw;
    padding-top: 5.67vw;
    width: 55.914vw;
  }
  @media ${breakpoints.tabletM} {
    margin-bottom: 10.363vw;
    padding-top: 7.806vw;
    width: 76.985vw;
  }
  @media ${breakpoints.mobile} {
    margin-bottom: 24.138vw;
    padding-top: 8.15vw;
    width: 80.878vw;
  }

  & > ${Link_HbCard} {
    max-width: 85%;
    ${paddings.side46}
    margin-bottom: 20px;
    @media ${breakpoints.desktopXl} {
      margin-bottom: 1.564vw;
    }
    @media ${breakpoints.tabletL} {
      margin-bottom: 1.955vw;
    }
    @media ${breakpoints.tabletM} {
      margin-bottom: 2.692vw;
    }
    @media ${breakpoints.mobile} {
      margin-bottom: 6.27vw;
    }
  }

  & strong {
    display: contents;
    font-weight: 400;
    color: #fff;
  }
`;

export const FavWr = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 7px;

  @media ${breakpoints.desktopXl} {
    gap: 0.547vw;
  }
  @media ${breakpoints.tabletL} {
    gap: 0.684vw;
  }
  @media ${breakpoints.tabletM} {
    gap: 0.942vw;
  }
  @media ${breakpoints.mobile} {
    gap: 2.194vw;
  }
  & ${Fav} {
    flex-shrink: 0;
    position: static;
    opacity: 1;
    @media ${breakpoints.mobile} {
      width: 7.524vw;
      height: 7.524vw;
    }
  }
`;

export const ImageLabel = styled.div`
  ${paddings.side46}
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  margin-bottom: 24px;

  @media ${breakpoints.desktopXl} {
    margin-bottom: 1.876vw;
    row-gap: 2.502vw;
  }
  @media ${breakpoints.tabletL} {
    margin-bottom: 2.346vw;
    row-gap: 3.128vw;
  }
  @media ${breakpoints.tabletM} {
    margin-bottom: 3.23vw;
    row-gap: 4.307vw;
  }
  @media ${breakpoints.mobile} {
    margin-bottom: 3.762vw;
    row-gap: 7.524vw;
  }

  ${({ isartpreparation }) =>
    isartpreparation &&
    `
   display: grid;
  grid-template-columns: 1fr auto;
  @media ${breakpoints.mobile} {
    grid-template-columns: auto;
  }
  & ${ImageCaption} {
    grid-column: 1;
    grid-row: 1;
    @media ${breakpoints.mobile} {
      margin-bottom: 1.254vw;
    }
  }
  & ${Time} {
    grid-column: 2;
    grid-row: 1;
    @media ${breakpoints.mobile} {
      grid-row: 3;
      grid-column: 1;
    }
  }
  & ${FavWr} {
    grid-column: 1;
    grid-row: 2;
    @media ${breakpoints.mobile} {
      grid-row: 2;
    }
  }
  `}

  ${LegalDateTag} {
    ${convertPxToVw("margin-top", "20px")};
    ${mixins.fs14};
    color: var(--wh6);
  }
`;

export const MainImage = styled.img`
  align-self: stretch;
  border: 2px solid var(--wh02);
  border-radius: 40px;
  margin-bottom: 12px;
  @media ${breakpoints.desktopXl} {
    margin-bottom: 0.938vw;
  }
  @media ${breakpoints.tabletL} {
    margin-bottom: 1.173vw;
  }
  @media ${breakpoints.tabletM} {
    margin-bottom: 1.615vw;
  }
  @media ${breakpoints.mobile} {
    margin-bottom: 1.567vw;
    border-width: 1px;
    border-radius: 18px;
  }
`;

export const SliderWr = styled.div`
  max-width: 450px;
  @media ${breakpoints.desktopXl} {
    max-width: 35.184vw;
  }
  @media ${breakpoints.tabletL} {
    max-width: 43.988vw;
  }
  @media ${breakpoints.tabletM} {
    max-width: 60.565vw;
  }
  @media ${breakpoints.mobile} {
    max-width: 71.16vw;
  }
  & ul {
    padding: 0;
    margin-bottom: 0;
    & > div {
    }
  }
`;

export const Tags = styled.ul`
  display: flex;
  align-items: center;
  ${mixins.gap4}
  & li {
    background-color: var(--wh04);
    border-radius: 6px;
    padding: 5px 8px;
    ${mixins.fs14}
    ${mixins.gap4}
    line-height: 114%;
    color: var(--wh6);
    @media ${breakpoints.mobile} {
      font-size: 3.135vw;
    }
  }
`;
export const TagSlug = styled.li`
  background-color: var(--wh04);
  border-radius: 6px;
  padding: 5px 8px;
  ${mixins.fs14};
  line-height: 114%;
  color: var(--wh6);
  @media ${breakpoints.desktopXl} {
    border-radius: 0.469vw;
    padding: 0.391vw 0.625vw;
  }
  @media ${breakpoints.tabletL} {
    border-radius: 0.587vw;
    padding: 0.489vw 0.782vw;
  }
  @media ${breakpoints.tabletM} {
    border-radius: 0.808vw;
    padding: 0.673vw 1.077vw;
  }
  @media ${breakpoints.mobile} {
    border-radius: 1.881vw;
    padding: 1.567vw 2.508vw;
    font-size: 3.135vw;
  }
`;

export const AuthorsWr = styled.div`
  ${paddings.side46}
`;
export const Caption = styled.figcaption`
  ${fonts.artel4}
  ${mixins.fs12}
  line-height: 117%;
  letter-spacing: 0.02em;
  color: var(--wh6);
  padding: 0 20px;
  @media ${breakpoints.desktopXl} {
    padding: 0 1.564vw;
  }
  @media ${breakpoints.tabletL} {
    padding: 0 1.955vw;
  }
  @media ${breakpoints.tabletM} {
    padding: 0 2.692vw;
  }
  @media ${breakpoints.mobile} {
    padding: 0;
  }
`;

export const Figure = styled.figure`
  display: flex;
  flex-direction: column;
  ${mixins.gap12}
  padding: 12px 26px 32px;
  @media ${breakpoints.desktopXl} {
    padding: 0.938vw 2.033vw 2.502vw;
  }
  @media ${breakpoints.tabletL} {
    padding: 1.173vw 2.542vw 3.128vw;
  }
  @media ${breakpoints.tabletM} {
    padding: 1.615vw 3.499vw 4.307vw;
  }
  @media ${breakpoints.mobile} {
    padding: 3.762vw 0 10.031vw;
  }
`;
export const ArticleFooter = styled.div`
  padding-top: 36px;
  @media ${breakpoints.desktopXl} {
    padding-top: 2.815vw;
  }
  @media ${breakpoints.tabletL} {
    padding-top: 3.519vw;
  }
  @media ${breakpoints.tabletM} {
    padding-top: 4.845vw;
  }
  @media ${breakpoints.mobile} {
    padding-top: 10.031vw;
  }

  & > ${Link_HbCard} {
    max-width: 85%;
    ${paddings.side46}
    margin-bottom: 20px;
    @media ${breakpoints.desktopXl} {
      margin-bottom: 1.564vw;
    }
    @media ${breakpoints.tabletL} {
      margin-bottom: 1.955vw;
    }
    @media ${breakpoints.tabletM} {
      margin-bottom: 2.692vw;
    }
    @media ${breakpoints.mobile} {
      margin-bottom: 6.27vw;
    }
  }
`;
