import { convertPxToVw } from "@/services/convertPxToVw";
import { breakpoints, mixins } from "@/styles/variables/variables";
import styled from "styled-components";
import { ErrorLink } from "../footer/team/components";
import { ImgFoundation } from "../handbook/card/FoundationCardHeader";
import {
  FirstLineContainer,
  Picture_HbCard,
  Slug,
  Tag_HbCard,
} from "../handbook/components";

export const DateTagWr = styled.div`
  ${convertPxToVw("margin-bottom", "12px")}
  ${convertPxToVw("padding", "0 4px")}
`;
export const LegalDateTag = styled(Tag_HbCard)`
  width: fit-content;
`;
export const LegalCardSt = styled.div`
  flex-shrink: 0;
  background-color: var(--wh02);
  border-width: 1.74px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 8px 8px 12px;
  border-radius: 32px;
  position: relative;
  ${({ is_active }) =>
    `border-style: ${is_active ? "solid" : "dashed"}; 
    border-color: var(--wh${is_active ? "04" : "4"});`}

  @media ${breakpoints.desktopXl} {
    border-radius: 2.502vw;
    padding: 0.625vw 0.625vw 0.938vw;
  }
  @media ${breakpoints.tabletL} {
    border-radius: 3.128vw;
    padding: 0.782vw 0.782vw 1.173vw;
  }
  @media ${breakpoints.tabletM} {
    border-radius: 4.307vw;
    padding: 1.077vw 1.077vw 1.615vw;
  }
  @media ${breakpoints.mobile} {
    border-radius: 10.031vw;
    padding: 2.508vw 2.508vw 3.762vw;
  }
  & > ${ErrorLink} {
    align-self: center;
    margin-top: 11px;
    @media ${breakpoints.desktopXl} {
      margin-top: 0.86vw;
    }
    @media ${breakpoints.tabletL} {
      margin-top: 1.075vw;
    }
    @media ${breakpoints.tabletM} {
      margin-top: 1.48vw;
    }
    @media ${breakpoints.mobile} {
      margin-top: 3.448vw;
    }
    & > span {
      ${mixins.fs8}
      color: var(--wh2);
      &:hover {
        color: var(--wh4);
      }
      &:active {
        color: var(--wh2);
      }
    }
  }

  &:hover {
    & ${Slug} {
      opacity: 1;
    }
    & ${Picture_HbCard}::before {
      opacity: 1;
    }

    & ${ImgFoundation} {
      transform: scale(1.05);
    }

    & img[data-svg="animate-svg"] {
      transform: scale(1.05) translate(-50%, -50%);
    }
  }
  ${({ hovered }) =>
    hovered &&
    `
  & ${Slug}{
      opacity: 1;
    }
    & ${Picture_HbCard}::before {
      opacity: 1;
    }
    & ${ImgFoundation} {
      transform: scale(1.05);
    }

    & img[data-svg="animate-svg"] {
      transform: scale(1.05) translate(-50%, -50%);
    }
  `}
`;

export const FirstLineLegalContainer = styled(FirstLineContainer)`
  & > ${LegalCardSt} {
    width: 257px;
    @media ${breakpoints.desktopXl} {
      width: 20.094vw;
    }
    @media ${breakpoints.tabletL} {
      width: 25.122vw;
    }
    @media ${breakpoints.tabletM} {
      width: 34.59vw;
    }
    @media ${breakpoints.mobile} {
      width: 80.564vw;
    }
  }
`;
