import { convertPxToVw } from "@/services/convertPxToVw";
import { breakpoints, fonts, mixins } from "@/styles/variables/variables";
import styled from "styled-components";
import css from "styled-jsx/css";
import { Link_HbCard } from "../components";

export const paddings = {
  side64_44: css`
    padding-right: 64px;
    padding-left: 44px;
    @media ${breakpoints.desktopXl} {
      padding-right: 5.004vw;
      padding-left: 3.44vw;
    }
    @media ${breakpoints.tabletL} {
      padding-right: 6.256vw;
      padding-left: 4.301vw;
    }
    @media ${breakpoints.tabletM} {
      padding-right: 8.614vw;
      padding-left: 5.922vw;
    }
    @media ${breakpoints.mobile} {
      padding-right: 0;
      padding-left: 0;
    }
  `,
  side46: css`
    padding-left: 46px;
    padding-right: 46px;
    @media ${breakpoints.desktopXl} {
      padding-left: 3.597vw;
      padding-right: 3.597vw;
    }
    @media ${breakpoints.tabletL} {
      padding-left: 4.497vw;
      padding-right: 4.497vw;
    }
    @media ${breakpoints.tabletM} {
      padding-left: 6.191vw;
      padding-right: 6.191vw;
    }
    @media ${breakpoints.mobile} {
      padding-left: 0;
      padding-right: 0;
    }
  `,
  top12: css`
    padding-top: 12px;
    @media ${breakpoints.desktopXl} {
      padding-top: 0.938vw;
    }
    @media ${breakpoints.tabletL} {
      padding-top: 1.173vw;
    }
    @media ${breakpoints.tabletM} {
      padding-top: 1.615vw;
    }
    @media ${breakpoints.mobile} {
      padding-top: 3.762vw;
    }
  `,
  bottom36: css`
    padding-bottom: 36px;
    @media ${breakpoints.desktopXl} {
      padding-bottom: 2.815vw;
    }
    @media ${breakpoints.tabletL} {
      padding-bottom: 3.519vw;
    }
    @media ${breakpoints.tabletM} {
      padding-bottom: 4.845vw;
    }
    @media ${breakpoints.mobile} {
      padding-bottom: 11.285vw;
    }
  `,
  bottom28: css`
    padding-bottom: 28px;
    @media ${breakpoints.desktopXl} {
      padding-bottom: 2.189vw;
    }
    @media ${breakpoints.tabletL} {
      padding-bottom: 2.737vw;
    }
    @media ${breakpoints.tabletM} {
      padding-bottom: 3.769vw;
    }
    @media ${breakpoints.mobile} {
      padding-bottom: 8.777vw;
    }
  `,
  bottom24: css`
    padding-bottom: 24px;
    @media ${breakpoints.desktopXl} {
      padding-bottom: 1.876vw;
    }
    @media ${breakpoints.tabletL} {
      padding-bottom: 2.346vw;
    }
    @media ${breakpoints.tabletM} {
      padding-bottom: 3.23vw;
    }
    @media ${breakpoints.mobile} {
      padding-bottom: 7.524vw;
    }
  `,
  bottom20: css`
    padding-bottom: 20px;
    @media ${breakpoints.desktopXl} {
      padding-bottom: 1.564vw;
    }
    @media ${breakpoints.tabletL} {
      padding-bottom: 1.955vw;
    }
    @media ${breakpoints.tabletM} {
      padding-bottom: 2.692vw;
    }
    @media ${breakpoints.mobile} {
      padding-bottom: 6.27vw;
    }
  `,
  bottom16: css`
    padding-bottom: 16px;
    @media ${breakpoints.desktopXl} {
      padding-bottom: 16px;
    }
    @media ${breakpoints.tabletL} {
      padding-bottom: 16px;
    }
    @media ${breakpoints.tabletM} {
      padding-bottom: 16px;
    }
    @media ${breakpoints.mobile} {
      padding-bottom: 16px;
    }
  `,
};

export const H1 = styled.h1`
  ${fonts.artel6}
  letter-spacing: -0.02em;
  line-height: 106%;
  color: var(--wh9);
  font-size: 36px;
  margin-bottom: 12px;
  @media ${breakpoints.desktopXl} {
    font-size: 2.815vw;
    margin-bottom: 0.938vw;
  }
  @media ${breakpoints.tabletL} {
    font-size: 3.519vw;
    margin-bottom: 1.173vw;
  }
  @media ${breakpoints.tabletM} {
    font-size: 4.845vw;
    margin-bottom: 1.615vw;
  }
  @media ${breakpoints.mobile} {
    font-size: 8.15vw;
    margin-bottom: 3.762vw;
  }

  ${paddings.side46}
`;
export const Description = styled.p`
  ${fonts.artel4}
  ${mixins.fs20}
  color: var(--wh6);
  font-size: 20px;
  line-height: 140%;
  letter-spacing: -0.01em;
  padding: 0px 46px;
  ${paddings.side46}
  margin-bottom: 24px;
  @media ${breakpoints.desktopXl} {
    margin-bottom: 1.876vw;
  }
  @media ${breakpoints.tabletL} {
    margin-bottom: 2.346vw;
  }
  @media ${breakpoints.tabletM} {
    margin-bottom: 3.23vw;
  }
  @media ${breakpoints.mobile} {
    margin-bottom: 7.524vw;
    font-size: 5.016vw;
  }
`;
export const H2 = styled.h2`
  ${fonts.artel6}
  line-height: 110%;
  letter-spacing: -0.01em;
  color: #fff;
  ${mixins.fs20}
  ${paddings.side46}
  ${paddings.top12}
  ${paddings.bottom28}
`;
export const H3 = styled.h3`
  ${fonts.artel6}
  ${mixins.fs16}
  line-height: 137%;
  color: #fff;
  ${paddings.side46}
  ${paddings.top12}
  ${paddings.bottom20}
`;
export const H4 = styled.h4`
  ${fonts.artel6}
  ${mixins.fs14}
  line-height: 114%;
  color: #fff;
  ${paddings.side46}
  ${paddings.top12}
  ${paddings.bottom16}
`;

export const H5 = styled.h5`
  ${fonts.artel6}
  ${mixins.fs12}
  line-height: 133%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: var(--wh5);
  ${paddings.side46};
  ${paddings.top12};
  transform: translateY(8px);
  @media ${breakpoints.desktopXl} {
    transform: translateY(0.625vw);
  }
  @media ${breakpoints.tabletL} {
    transform: translateY(0.782vw);
  }
  @media ${breakpoints.tabletM} {
    transform: translateY(1.077vw);
  }
  @media ${breakpoints.mobile} {
    transform: translateY(2.508vw);
  }
`;

export const spanStyles = css`
  ${fonts.artel4}
  ${mixins.fs12}
  color: var(--wh6);
  line-height: 117%;
  letter-spacing: 0.02em;
  @media ${breakpoints.mobile} {
    font-size: 3.135vw;
  }
`;

export const H6 = styled.h6`
  ${paddings.side46}
  ${paddings.top12}
  ${paddings.bottom24}
  ${spanStyles}
`;
export const ImageLink = styled.a.attrs({
  target: "_blank",
  rel: "noopener noreferrer",
})`
  display: block;
  transition: color 0.3s;
  ${spanStyles};
  color: var(--wh4);
  grid-column: 1;
  @media ${breakpoints.mobile} {
    margin-bottom: 1.254vw;
  }
  &:hover {
    color: var(--wh6);
  }
  &:active {
    color: var(--wh3);
  }
`;
export const ImageText = styled.p`
  ${spanStyles};
  color: var(--wh4);
  grid-column: 1;
  @media ${breakpoints.mobile} {
    margin-bottom: 1.254vw;
  }
`;
export const Time = styled.span`
  ${fonts.artel6}
  ${mixins.fs10}
  line-height: 120%;
  letter-spacing: 0.1em;
  color: var(--wh4);
  grid-row: 1;
  grid-column: 2;
  display: inline-flex;
  align-items: center;
  justify-self: end;
  text-transform: uppercase;
  @media ${breakpoints.mobile} {
    grid-column: 1;
    grid-row: 3;
    justify-self: start;
  }
  & > svg {
    transform: scale(0.9) translateY(1%);
    width: 14px;
    margin-right: 4px;
    height: 100%;
    @media ${breakpoints.desktopXl} {
      width: 1.095vw;
      margin-right: 0.313vw;
    }
    @media ${breakpoints.tabletL} {
      width: 1.369vw;
      margin-right: 0.391vw;
    }
    @media ${breakpoints.tabletM} {
      width: 1.884vw;
      margin-right: 0.538vw;
    }
    @media ${breakpoints.mobile} {
      height: 3.135vw;
      width: 3.135vw;
      margin-right: 1.881vw;
    }
  }
`;

export const Text = styled.p`
  ${fonts.artel4}
  ${mixins.fs16}
  color: var(--wh7);
  line-height: 150%;
  ${paddings.side46}
  ${paddings.bottom24}
`;
export const Quote = styled.blockquote`
  ${fonts.artel4}
  ${mixins.fs16}
  color: var(--wh5);
  line-height: 150%;
  ${paddings.side46}
  ${paddings.top12}
  ${paddings.bottom36}

  & ${Text} {
    ${mixins.fs20}
    line-height: 140%;
    letter-spacing: -0.01em;
    color: var(--wh5);
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 20px;
    @media ${breakpoints.desktopXl} {
      padding-left: 1.564vw;
    }
    @media ${breakpoints.tabletL} {
      padding-left: 1.955vw;
    }
    @media ${breakpoints.tabletM} {
      padding-left: 2.692vw;
    }
    @media ${breakpoints.mobile} {
      padding-left: 6.27vw;
    }
    position: relative;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      height: 100%;
      width: 2px;
      background-image: linear-gradient(
        var(--wh02) 0%,
        var(--wh08) 50%,
        var(--wh02) 100%
      );
    }
  }
`;

const List = styled.ul`
  ${paddings.bottom36}
  ${paddings.side64_44}
  display: flex;
  flex-direction: column;
  ${mixins.gap8}

  & > li {
    ${fonts.artel4}
    ${mixins.fs14}
    color: var(--wh7);
    line-height: 143%;
    letter-spacing: 0.01em;
    /* display: flex; */
    display: inline-block;
    position: relative;
    ${convertPxToVw("padding-left", "28px")}
    &::before {
      position: absolute;
      left: 0;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0.87px solid var(--wh4);
      border-radius: 8px;
      width: 20px;
      height: 20px;
      margin-right: 8px;
      @media ${breakpoints.desktopXl} {
        width: 1.564vw;
        height: 1.564vw;
        margin-right: 0.625vw;
      }
      @media ${breakpoints.tabletL} {
        width: 1.955vw;
        height: 1.955vw;
        margin-right: 0.782vw;
      }
      @media ${breakpoints.tabletM} {
        width: 2.692vw;
        height: 2.692vw;
        margin-right: 1.077vw;
      }
      @media ${breakpoints.mobile} {
        width: 6.27vw;
        height: 6.27vw;
        margin-right: 2.508vw;
      }
      ${mixins.fs12}
      text-align: center;
      color: var(--wh5);
    }
  }
`;
export const NumList = styled(List).attrs({
  as: "ol",
})`
  & > li {
    counter-increment: counter;
    &::before {
      content: counter(counter);
      line-height: 117%;
      letter-spacing: 0.02em;
    }
  }
`;

export const BulletList = styled(List)`
  & > li::before {
    content: "→";
    line-height: 133%;
    letter-spacing: 0.06em;
    padding-bottom: 2px;
    height: 18px;
    @media ${breakpoints.desktopXl} {
      padding-bottom: 0.156vw;
      height: 1.407vw;
    }
    @media ${breakpoints.tabletL} {
      padding-bottom: 0.196vw;
      height: 1.76vw;
    }
    @media ${breakpoints.tabletM} {
      padding-bottom: 0.269vw;
      height: 2.423vw;
    }
    @media ${breakpoints.mobile} {
      padding-bottom: 0.627vw;
      height: 5.643vw;
    }
  }
`;

export const CheckboxList = styled(List)`
  & input {
    display: none;
  }
  & > li::before {
    content: "✓";
    line-height: 133%;
    letter-spacing: 0.06em;
  }
`;

export const WrapperWithBg = styled.div`
  ${({ pb }) =>
    pb
      ? `
  padding: 0 26px 24px;
  @media ${breakpoints.desktopXl} {
    padding: 0 2.033vw 1.876vw;
  }
  @media ${breakpoints.tabletL} {
    padding: 0 2.542vw 2.346vw;
  }
  @media ${breakpoints.tabletM} {
    padding: 0 3.499vw 3.23vw;
  }
  @media ${breakpoints.mobile} {
    padding: 0 0 7.524vw;
  }  
    `
      : `
  padding: 12px 26px 0;
  @media ${breakpoints.desktopXl} {
    padding: 0.938vw 2.033vw 0;
  }
  @media ${breakpoints.tabletL} {
    padding: 1.173vw 2.542vw 0;
  }
  @media ${breakpoints.tabletM} {
    padding: 1.615vw 3.499vw 0;
  }
  @media ${breakpoints.mobile} {
    padding: 0;
  }
  `}
  & > div {
    background-color: var(--wh02);
    border-radius: 32px;
    border: 1px solid var(--wh04);
    ${({ pb }) =>
      pb
        ? `
      padding: 24px 32px 32px 28px;
      @media ${breakpoints.desktopXl} {
        padding: 1.876vw 2.502vw 2.502vw 2.189vw;
      }
      @media ${breakpoints.tabletL} {
        padding: 2.346vw 3.128vw 3.128vw 2.737vw;
      }
      @media ${breakpoints.tabletM} {
        padding: 3.23vw 4.307vw 4.307vw 3.769vw;
      }
      @media ${breakpoints.mobile} {
        padding: 7.524vw 10.031vw 10.031vw 8.777vw;
      }
    `
        : `
    padding: 32px 32px 48px 28px;
    @media ${breakpoints.desktopXl} {
      padding: 2.502vw 2.502vw 3.753vw 2.189vw;
    }
    @media ${breakpoints.tabletL} {
      padding: 3.128vw 3.128vw 4.692vw 2.737vw;
    }
    @media ${breakpoints.tabletM} {
      padding: 4.307vw 4.307vw 6.46vw 3.769vw;
    }
    @media ${breakpoints.mobile} {
      padding: 10.031vw 7.524vw 15.047vw 6.27vw;
    }
    `};
    & > ${Text} {
      margin-top: 10px;
      @media ${breakpoints.desktopXl} {
        margin-top: 0.782vw;
      }
      @media ${breakpoints.tabletL} {
        margin-top: 0.978vw;
      }
      @media ${breakpoints.tabletM} {
        margin-top: 1.346vw;
      }
      @media ${breakpoints.mobile} {
        margin-top: 3.135vw;
      }
    }
  }
  & h4 {
    padding: 0;
  }
  & ${CheckboxList}, ${NumList} {
    margin-top: 20px;
    @media ${breakpoints.desktopXl} {
      margin-top: 1.564vw;
    }
    @media ${breakpoints.tabletL} {
      margin-top: 1.955vw;
    }
    @media ${breakpoints.tabletM} {
      margin-top: 2.692vw;
    }
    @media ${breakpoints.mobile} {
      margin-top: 6.27vw;
    }
    padding: 0;
  }
  & ${Text} {
    padding: 0;
    ${mixins.fs14}
    line-height: 143%;
    letter-spacing: 0.01em;
    color: var(--wh6);
  }
`;

export const Link = styled(Link_HbCard)`
  max-width: 70%;
  margin-bottom: 0;
  ${({ mt }) => mt && `margin-top: 1em; max-width: 100%;`}
`;
