import { getVideoExtension, isVideoRegexp } from "@/shared/constants/regexp";
import { breakpoints } from "@/styles/variables/variables";
import { useMediaQuery } from "@mui/material";
import { useInViewport } from "ahooks";
import { useRouter } from "next/router";
import { useEffect, useRef } from "react";
import styled from "styled-components";

export const FoundationCardHeader = ({ image, slug, hovered }) => {
  const { pathname } = useRouter();
  const isMobile = useMediaQuery(breakpoints.mobile);

  return (
    <Wr
      // target="_blank"
      href={pathname + "/" + slug}
    >
      {isVideoRegexp.test(image) ? (
        isMobile ? (
          <VideoFoundationMobile src={image} />
        ) : (
          <VideoFoundation hovered={hovered} src={image} />
        )
      ) : (
        <ImgFoundation src={image} />
      )}
    </Wr>
  );
};

const Wr = styled.a`
  overflow: hidden;
  display: block;
  /* z-index: -1; */
  height: 173px;
  margin-bottom: 12px;
  border-radius: 24px;
  @media ${breakpoints.desktopXl} {
    border-radius: 1.876vw;
    margin-bottom: 0.938vw;
    height: 13.526vw;
  }
  @media ${breakpoints.tabletL} {
    border-radius: 2.346vw;
    margin-bottom: 1.173vw;
    height: 16.911vw;
  }
  @media ${breakpoints.tabletM} {
    border-radius: 3.23vw;
    margin-bottom: 1.615vw;
    height: 23.284vw;
  }
  @media ${breakpoints.mobile} {
    border-radius: 7.524vw;
    margin-bottom: 3.762vw;
    height: 54.232vw;
  }

  &:hover {
    & > img {
      transform: scale(1.05);
    }
    & ~ a #slug-bg {
      fill-opacity: 0.08;
    }
  }
  &:active {
    & ~ a #slug-bg {
      transform: scale(0.9);
    }
  }
`;
export const ImgFoundation = styled.img`
  transition: transform 0.3s;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const VideoFoundation = ({ src, hovered }) => {
  const video = useRef(null);
  const extension = src.match(getVideoExtension)[0].slice(1);

  useEffect(() => {
    if (hovered) {
      video.current.play();
      video.current.loop = true;
    } else {
      video.current.loop = false;
    }

    return () => {};
  }, [hovered]);

  const OnEnded = (e) => (e.target.currentTime = 0);

  return (
    <Video
      ref={video}
      width="100%"
      height="100%"
      // loop
      onEnded={OnEnded}
      muted="muted"
      preload="metadata"
      controls={false}
      playsInline
      autoPlay={false}
    >
      <source src={src} type={`video/${extension ?? "mp4"}`} />
      Тег video не поддерживается вашим браузером.
    </Video>
  );
};
const VideoFoundationMobile = ({ src }) => {
  const video = useRef(null);
  const extension = src.match(getVideoExtension)[0].slice(1);
  const [showVideo] = useInViewport(video, { threshold: 0.7 });

  useEffect(() => {
    if (showVideo) {
      video.current.play();
      video.current.loop = true;
    } else {
      video.current.loop = false;
    }

    return () => {};
  }, [showVideo]);

  const OnEnded = (e) => (e.target.currentTime = 0);

  return (
    <Video
      ref={video}
      width="100%"
      height="100%"
      // loop
      onEnded={OnEnded}
      muted="muted"
      preload="metadata"
      controls={false}
      playsInline
      autoPlay={false}
    >
      <source src={src} type={`video/${extension ?? "mp4"}`} />
      Тег video не поддерживается вашим браузером.
    </Video>
  );
};
